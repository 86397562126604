export interface ItemGenerico {
  tipo: Tipo | string;
  titulo: string;
  icono: string;
  ruta?: string;
}

export interface Item extends ItemGenerico {
  submenuVisible: boolean;
  hijos: ItemGenerico[];
}

enum Tipo {
  Autorreserva = 'autorreserva',
  Administracion = 'administracion',
  Herramientas = 'herramientas',
  Creacion = 'creacion',
  Gastos = 'gastos'
}

export class Constantes {
  public static itemsMenu: Item[] = [
    {
      tipo: Tipo.Autorreserva,
      titulo: 'sidebar.menu.autoReserva',
      icono: 'fas fa-suitcase-rolling',
      submenuVisible: true,
      hijos: [
        {
          tipo: 'hoteles',
          titulo: 'sidebar.menu.hoteles',
          icono: 'fas fa-hotel',
          ruta: '/autorreserva/hoteles'
        },
        {
          tipo: 'apartamentos',
          titulo: 'sidebar.menu.apartamentos',
          icono: 'fas fa-building',
          ruta: '/autorreserva/apartamentos'
        },
        {
          tipo: 'vuelos',
          titulo: 'sidebar.menu.vuelos',
          icono: 'fas fa-plane',
          ruta: '/autorreserva/vuelos'
        },
        {
          tipo: 'rent-a-car',
          ruta: '/autorreserva/rentacar',
          titulo: 'sidebar.menu.rentacar',
          icono: 'fas fa-car'
        },
        {
          tipo: 'transfers',
          ruta: '/autorreserva/transfers',
          titulo: 'sidebar.menu.transfers',
          icono: 'fas fa-taxi'
        },
        {
          tipo: 'trenes',
          ruta: '/autorreserva/trenes',
          titulo: 'auto-reserva.trenes.disponibilidad.form-busqueda.trenes',
          icono: 'fas fa-train'
        },
        {
          tipo: 'barcos',
          ruta: '/autorreserva/barcos',
          titulo: 'auto-reserva.barcos.disponibilidad.form-busqueda.Barcos',
          icono: 'fas fa-ship'
        },
        {
          tipo: 'misPresupuestos',
          ruta: '/autorreserva/mis-presupuestos',
          titulo: 'sidebar.menu.mis-presupuestos',
          icono: 'fa fa-file-alt',
        }
      ]
    },
    {
      tipo: Tipo.Gastos,
      titulo: 'sidebar.menu.gastos-viaje.titulo',
      icono: 'far fa-credit-card',
      submenuVisible: true,
      hijos: [
        {
          tipo: 'introducir-gastos',
          ruta: '/gastos/introducir-gastos',
          titulo: 'sidebar.menu.gastos-viaje.introducir',
          icono: 'fas fa-plus-circle'
        },
        {
          tipo: 'informe-gastos',
          ruta: '/gastos/informe',
          titulo: 'sidebar.menu.gastos-viaje.informe',
          icono: 'fas fa-book'
        }
      ]
    },
    {
      tipo: Tipo.Administracion,
      titulo: 'sidebar.menu.administracion',
      icono: 'fas fa-chart-line',
      submenuVisible: true,
      hijos: [
        {
          tipo: 'Albaranes-admin',
          ruta: '/administracion/albaranes',
          titulo: 'sidebar.menu.albaranes',
          icono: 'far fa-file-alt'
        },
        {
          tipo: 'Facturas-admin',
          ruta: '/administracion/facturas',
          titulo: 'sidebar.menu.facturas',
          icono: 'far fa-money-bill-alt'
        },
        {
          tipo: 'Informes-admin',
          ruta: '/administracion/informes',
          titulo: 'sidebar.menu.informes',
          icono: 'fa fa-book'
        },
        {
          tipo: 'Consumos-admin',
          ruta: '/administracion/consumos',
          titulo: 'sidebar.menu.consumos',
          icono: 'far fa-chart-bar'
        },
        {
          tipo: 'Analytics-admin',
          ruta: '/administracion/analytics',
          titulo: 'sidebar.menu.analytics',
          icono: 'fas fa-chart-line'
        }
      ]
    },
    {
      tipo: Tipo.Herramientas,
      titulo: 'sidebar.menu.herramientas',
      icono: 'fas fa-tools',
      submenuVisible: true,
      hijos: [
        {
          tipo: 'agenda',
          ruta: '/administracion/calendar',
          titulo: 'sidebar.menu.agenda',
          icono: 'fa fa-calendar'
        },
        {
          tipo: 'geoposicion',
          ruta: '/administracion/geoposicion',
          titulo: 'sidebar.menu.geoposicion',
          icono: 'fa fa-map'
        },
        {
          tipo: 'usuarios',
          ruta: '/administracion-usuarios',
          titulo: 'sidebar.menu.usuarios',
          icono: 'fa fa-users'
        },
        {
          tipo: 'ultimasReservas',
          ruta: '/herramientas/misUltimasReservas',
          titulo: 'sidebar.menu.misultimasreservas',
          icono: 'fa fa-archive'
        },
        {
          tipo: 'gestionProyectos',
          ruta: '/herramientas/gestion-proyectos',
          titulo: 'sidebar.menu.gestionproyectos',
          icono: 'fas fa-swatchbook'
        }
      ]
    },
    {
      tipo: Tipo.Creacion,
      titulo: 'sidebar.menu.mas-herramientas',
      icono: 'fas fa-plus-square',
      submenuVisible: true,
      hijos: [
        {
          tipo: 'alta-usuarios',
          ruta: '/administracion/alta-usuarios',
          titulo: 'dashboard.alta-usuarios',
          icono: 'fa fa-user-plus'
        },
        {
          tipo: 'alta-tarjetas',
          ruta: '/administracion/alta-tarjetas',
          titulo: 'dashboard.alta-tarjeta',
          icono: 'fa fa-credit-card'
        }
      ]
    }
  ];
}

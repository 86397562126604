import { Injectable } from '@angular/core';
import { Empresa, Usuario, UsuarioAdmin } from '../modelos/usuario';

@Injectable({
  providedIn: 'root'
})
export class PermisosService {
  
  constructor() { }

  private _infoUser: Usuario;
  private _userInfoAdmin: UsuarioAdmin;

  permisoInformesGastos() {
    this.setUsuarios();
    if (!this._infoUser?.ControlGastos?.Entidades || this._infoUser?.ControlGastos?.Entidades?.length === 0) return false;
    return true;
  }

  permisoAdministracionUsuarios(): boolean {
    this.setUsuarios();
    return this._userInfoAdmin?.Empresas?.some(empresa => empresa.AdminUsuarios === 2);
  }

  private setUsuarios() {
    this._infoUser = JSON.parse(localStorage.getItem('info_user'));
    this._userInfoAdmin =  JSON.parse(localStorage.getItem('info_user_admin'));
  }

  permisoGestionProyectos(): boolean {
    this._userInfoAdmin = JSON.parse(localStorage.getItem('info_user_admin'));
    return (this._userInfoAdmin && this._userInfoAdmin.hasOwnProperty('Empresas')) ? this.comprobarPermisosGestionProyectos(this._userInfoAdmin.Empresas) : false;
  }

  comprobarPermisosGestionProyectos(empresasListado: Empresa[]): boolean {
      return empresasListado.some((empresa)=> {
        return empresa.Proyectos != 0;
      });
    }
}
